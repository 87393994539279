html body {
  background-color: #fafafa;
}

.App-header {
  background-color: #20343b;
  height: 60px;
  padding: 20px;
  color: white;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-title {
  font-size: 1.5rem;
  text-align: center;
}
