*,
*:before,
*:after {
  box-sizing: border-box;
}

.Editor-flexcontainer {
  display: flex;
  flex-direction: row;
  margin: 4rem 2rem;
}

.Editor-outside-wrapper {
  flex: 2 0 0;
  overflow: hidden;
  margin: 0 4rem;
}

.Editor-controls {
  flex: 1 0 0;
  margin-right: 2rem;
}

.Editor-stage .konvajs-content {
  max-width: 100%;
}

.Editor-scalable-wrapper {
  resize: both;
  position: relative;
  transform-origin: center center;
}

.Editor-interior {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
}

input[type='text'],
input[type='range'] {
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  background: #efefef;
  border-radius: 4px;
  border: none;
  margin-top: 0.5rem;
}

select,
input[type='file'],
input[type='range'] {
  display: block;
  width: 100%;
}

.Editor-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Editor-upload {
  cursor: pointer;
  display: block;
  color: white;
  margin: 1rem 0 0;
  padding: 0.8rem 2rem;
  border-radius: 25px;
  background: linear-gradient;
  background: rgb(105, 232, 148);
  background: linear-gradient(
    90deg,
    rgba(105, 232, 148, 1) 0%,
    rgba(105, 193, 222, 1) 100%
  );
  transition: all 0.2s ease-in-out;
}
.Editor-upload:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
}
#upload {
  display: none;
}

.Editor-size {
  margin-bottom: 2rem;
}

.Editor-size input {
  margin: 0.75rem 0 0 0;
}

select {
  font-size: 1rem;
  padding: 0.5rem;
  background: #efefef;
  border-radius: 4px;
  border: none;
  margin-top: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.Editor-select {
  position: relative;
  display: block;
}
.Editor-select:after {
  content: '▾';
  position: absolute;
  right: 1rem;
  top: 0;
  line-height: 2.3rem;
}

.Editor-preview {
  max-width: 50px;
  max-height: 50px;
}

.Editor-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Editor-swatches-container {
  height: 8rem;
}

.Editor-swatches {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1rem;
}

.Editor-swatch-hover {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #00ff6c;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.Editor-swatch:hover .Editor-swatch-hover {
  opacity: 1;
}

.Editor-swatches input {
  display: none;
}

.Editor-swatch {
  cursor: pointer;
  position: relative;
  height: 80px;
  min-width: 90px;
  margin: 0 0.5em;
  background-color: #fff;
  background-image: linear-gradient(
      45deg,
      #ccc 25%,
      transparent 25%,
      transparent 75%,
      #ccc 75%,
      #ccc
    ),
    linear-gradient(
      45deg,
      #ccc 25%,
      transparent 25%,
      transparent 75%,
      #ccc 75%,
      #ccc
    );
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  border: 2px solid #fafafa;
  transition: all 0.1s ease-in-out;
}

.Editor-swatch .Editor-swatch-inside {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.Editor-download {
  cursor: pointer;
  color: white;
  border: none;
  font-size: 1rem;
  margin: 1rem 0;
  padding: 1rem 2rem;
  border-radius: 25px;
  background: linear-gradient;
  background: rgb(105, 232, 148);
  background: linear-gradient(
    90deg,
    rgba(105, 232, 148, 1) 0%,
    rgba(105, 193, 222, 1) 100%
  );
  transition: all 0.2s ease-in-out;
}
.Editor-download:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
}

.Editor-reset {
  color: #ff5b3c;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

@media screen and (max-width: 900px) {
  .Editor-flexcontainer {
    display: flex;
    flex-direction: column;
    margin: 4rem 2rem;
  }
  .Editor-outside-wrapper {
    order: -1;
    margin: 0 0 4rem 0;
  }
  .Editor-controls {
    margin-right: 0;
  }
}
